import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { useTelegram } from "../hooks/useTelegram";
import { useTypedSelector } from "../hooks/useTypedSelector";

import {
    CoursePageCover,
    CoursePageProgramm,
    CoursePageForm,
    Loader,
    Thank,
    BackBtn,
} from "../components/";

import { fetchCourseById } from "../redux/actions/course";

const CoursePage: React.FC = () => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const { user } = useTelegram();

    const [isSend, setIsSend] = React.useState<boolean>(false);

    const { itemById, isLoadedById } = useTypedSelector(({ course }) => course);

    React.useEffect(() => {
        dispatch(fetchCourseById(id ? id : "") as any);
    }, []);

    const onSubmit = (data: any) => {
        const { name, email, phone } = data;

        axios.post(`${process.env.REACT_APP_API_AWO_DOMEN}/goods/buy`, {
            name,
            email,
            phone,
            idAwo: itemById.idAwo,
            message: "Заявка из ТГ бота",
            telegram_user: user.username,
        });

        setIsSend(true);
    };

    React.useEffect(() => {
        if (isLoadedById) {
            document.location.reload();
        }
    }, []);

    React.useEffect(() => {
        if (itemById.customFormAwo && isLoadedById) {
            document
                ?.querySelector("#awo-form")
                ?.setAttribute("src", itemById.customFormAwoUrl);
        }
    }, [isLoadedById]);

    return (
        <>
            {isLoadedById ? (
                <section className="course-page">
                    <div className="container">
                        <BackBtn />

                        {isSend ? (
                            <Thank {...itemById} />
                        ) : (
                            <div className="course-page-wrapper">
                                <CoursePageCover {...itemById} />

                                <CoursePageProgramm {...itemById.programm} />

                                {itemById.customFormAwo ? (
                                    <>
                                        <h3
                                            className="timetable-page-form__title"
                                            style={{ marginTop: "15px" }}
                                        >
                                            {itemById.formTitle}
                                        </h3>

                                        <button
                                            onClick={() =>
                                                window.awoOpenFormModal(
                                                    itemById.customFormAwoId
                                                )
                                            }
                                            className="btn timetable-page-form__btn"
                                        >
                                            Зарегистрироваться
                                        </button>
                                    </>
                                ) : (
                                    <CoursePageForm
                                        onSubmit={onSubmit}
                                        {...itemById}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </section>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default CoursePage;
